<template>
  <div class="service-network-wrap ">
    <!--    <content-key letter="Service point" title="办事服务点"></content-key>-->
    <div class="flex">
      <div class="mapList">
        <div class="toptab">
          <div class="tab-contents">
            办事网点
          </div>
        </div>
        <div class="center">
          <div class="service-network-item" v-for="(item, index) in list" :key="index">
            <p class="wdName" @click.prevent="goToBsdt(item)">
              <img src="../../../assets/images/map-icon.png" />
              <span style="margin-left:5px; flex:1">{{ item.officeName }}</span>
            </p>
            <p class="smName">地址：{{ item.address }}</p>
            <!-- <p class="smName" v-show="!gzTime">工作时间：上午9:00-12：00，下午13:30-17：00</p> -->
            <p class="smName">工作时间：上午{{ item.workTimeAM }}&nbsp;下午{{ item.workTimePM }}</p>
            <p class="smName">电话：{{ item.dh }}</p>
            <p class="smName bottomSmName" @click.prevent="goToBsdt(item)">
              <a @click.stop="openBaidu(item)">
                <img src="../../../assets/images/dzq.png" />
              </a>
              <a @click.stop="openBsdt(item.vrAddress)">
                <img src="../../../assets/images/sjdt.png" />
              </a>
            </p>
          </div>
        </div>
      </div>
      <div id="allmap"></div>
    </div>
  </div>
</template>
<script>
import { findByDjjg } from '../../../api/uums';
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      gzTime: '',
      list: [],
      code: '460100'
    };
  },
  computed: {
    ...mapState('dashboard', ['selectedAreaCode', 'selectedBsdtCode', 'selectedShiTitle']),
    // 选择的机构信息
    getSelectedOrgInfo() {
      return this.$store.getters['dashboard/getSelectedOrgInfo'];
    }
  },
  watch: {
    selectedBsdtCode(newVal, oldVal) {
      if (!newVal || newVal === oldVal) return;
      this.initBsdtxy();
    }
    // selectedShiTitle(newVal, oldVal) {
    //   if (!newVal || newVal === oldVal) return;
    //   this.getMap(this.selectedShiTitle);
    // }
  },
  methods: {
    init() {
      this.LoadBaiduMapScript().then((BMap) => {
        window.map = new BMap.Map('allmap');
        // window.map.centerAndZoom(window.CONFIG.title, 12);
        // window.map.centerAndZoom('海南', 12);
        // eslint-disable-next-line no-undef
        window.map.addControl(new BMap.NavigationControl());
        this.$nextTick(() => {
          this.initBsdtxy();
        });
      });
    },
    //初始化获取办事大厅列表
    initBsdtxy() {
      findByDjjg({ djjg: this.getSelectedOrgInfo.djjg }).then((res) => {
        if (res && res.success) {
          this.list = res.data;
          let point = [];
          this.goToBsdt(res.data[0]);
          res.data.forEach((item) => {
            point.push({
              x: item.dx,
              y: item.dy,
              officeName: item.officeName
            });
          });
          // 在地图中增加办事大厅坐标
          this.addmarker(point);
        } else {
          this.$message.warning('获取办事大厅失败');
        }
      });
    },
    // 在地图中增加办事大厅坐标
    addmarker(point) {
      for (var i = 0; i < point.length; i++) {
        var p = new BMap.Point(point[i].x, point[i].y);
        var label = new BMap.Label(point[i].officeName, { offset: new BMap.Size(24, -10) });
        this.addMarker(p, label);
      }
    },
    addMarker(point, label) {
      var icon = require('../../../assets/images/map-icon.png');
      var myIcon = new BMap.Icon(icon, new BMap.Size(32, 47));
      var marker = new BMap.Marker(point, { icon: myIcon });
      window.map.addOverlay(marker);
      // 创建文本标注对象
      // label.setStyle({
      //   color: 'red',
      //   fontSize: '12px',
      //   height: '20px',
      //   lineHeight: '20px',
      //   fontFamily: '微软雅黑'
      // });
      marker.setLabel(label);
    },
    //到办事网点的具体位置
    openBaidu(item) {
      console.log('88888888888');
      //重新调用地图清除上次轨迹
      this.LoadBaiduMapScript().then((BMap) => {
        window.map = new BMap.Map('allmap');
        window.map.centerAndZoom(window.CONFIG.title, 12);
        // eslint-disable-next-line no-undef
        window.map.addControl(new BMap.NavigationControl());
      });
      //重新调用设置图标
      this.initBsdtxy();
      let geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function(r) {
        if (this.getStatus() === BMAP_STATUS_SUCCESS) {
          //定位
          const point = new BMap.Point(r.point.lng, r.point.lat);
          map.centerAndZoom(point, 15);
          var driving = new BMap.DrivingRoute(map, {
            renderOptions: {
              map: map,
              autoViewport: true
            }
          });
          const start = new BMap.Point(r.point.lng, r.point.lat);
          const end = new BMap.Point(item.dx, item.dy);
          driving.search(start, end);
        }
      });
    },
    // 点击办事大厅到办事大厅具体位置
    goToBsdt(item) {
      if (!item.dx || !item.dy) {
        return;
      }
      var point = new BMap.Point(item.dx, item.dy);
      map.centerAndZoom(point, 15);
    },

    // 实景大厅
    openBsdt(url) {
      if (url) {
        window.open(url);
      } else {
        this.$message.warning('此办事大厅暂无VR影像!');
      }
    },
    //百度api所需
    LoadBaiduMapScript() {
      const AK = 'OFbiGjm3W00GGFZHIysQZfeYs3GTAehF';
      const BMap_URL = 'https://api.map.baidu.com/api?v=2.0&ak=' + AK + '&s=1&callback=onBMapCallback';
      return new Promise((resolve, reject) => {
        // 如果已加载直接返回
        if (typeof BMap !== 'undefined') {
          resolve(BMap);
          return true;
        }
        // 百度地图异步加载回调处理
        window.onBMapCallback = function() {
          console.log('百度地图脚本初始化成功...');
          resolve(BMap);
        };
        // 插入script脚本
        let scriptNode = document.createElement('script');
        scriptNode.setAttribute('type', 'text/javascript');
        scriptNode.setAttribute('src', BMap_URL);
        document.body.appendChild(scriptNode);
      });
    }
    // getMap(selectedShiTitle) {
    //   this.LoadBaiduMapScript().then((BMap) => {
    //     window.map = new BMap.Map('allmap');
    //     window.map.centerAndZoom(selectedShiTitle, 12);
    //     window.map.addControl(new BMap.NavigationControl());
    //   });
    // }
  },
  mounted() {
    if (this.selectedAreaCode) {
      this.code = this.selectedAreaCode;
    }
    this.$nextTick(() => {
      this.init();
    });
  }
};
</script>
<style lang="scss" scoped>
.service-network-wrap {
  background: #fff;
  margin-top: 10px;

  #allmap {
    flex: 1;
    height: 838px;
  }

  .mapList {
    width: 456px;
    height: 838px;

    .toptab {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #e0e0e0;

      .tab-contents {
        padding-left: 60px;
        font-size: 24px;
        font-weight: bold;
        color: #333;
        background: url(../../../assets/images/dashboard/title-icon.png) no-repeat 24px center;
        background-size: 25px;
      }
    }

    .center {
      overflow: auto;
      height: 640px;

      .service-network-item {
        width: 100%;
        overflow: auto;

        &:hover {
          background: #f0f7ff;
        }
      }

      /* table {
        width: 100%;

        tr {
          &:hover {
            background: #f0f7ff;
          }
        }
      } */

      img {
        height: 16px;
        margin: 5px 10px;
      }

      p {
        color: #9a9a9a;
        font-size: 14px;
        padding-left: 40px;
      }

      .wdName {
        display: flex;
        margin: 26px 0 22px 0;
        padding-left: 0px;
        line-height: 26px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        cursor: pointer;
      }

      .wdName:hover {
        color: #3f80b6;
      }

      .smName {
        margin-bottom: 15px;
        padding: 0 0 0 40px;
        color: #666;
        font-size: 16px;
      }

      .bottomSmName {
        overflow: hidden;
        margin-top: 10px;

        a {
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
