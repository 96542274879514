<template>
  <div id="dashboardWrap full">
    <!--<HotService />-->
    <!--<PersonalCenter />-->
    <ServicePoint />
  </div>
</template>
<script>
// import HotService from '@/pages/iebdc/views/dashboard/child/hot-service.vue';
// import PersonalCenter from '@/pages/iebdc/views/dashboard/child/personal-center.vue';
import ServicePoint from '@/pages/iebdc/views/dashboard/child/service-point.vue';

export default {
  components: {
    // HotService,
    // PersonalCenter,
    ServicePoint
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.main-wrap .main-view {
  width: 100% !important;
  margin: 0 auto;
}

.banner {
  width: 100%;
  height: 437px;
  // background: url(../../assets/images/dashboard/bg.png) no-repeat center center;
  background-size: 100% 100%;
}
</style>
